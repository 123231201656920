// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Welcome to vidmancy')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

import 'animate.css'
import { Application } from "@hotwired/stimulus"
import "@hotwired/turbo-rails"
import "@rails/actiontext"
import "trix"
import AttachmentController from "./../controllers/attachment_controller"
import CaptionCheckerController from "./../controllers/caption_checker_controller"
import ClipboardController from "./../controllers/clipboard_controller"
import FormController from "./../controllers/form_controller"
import LazyImageController from "./../controllers/lazy_image_controller"
import SidebarController from "./../controllers/sidebar_controller"
import ToastController from "./../controllers/toast_controller"
import VideoPlayerController from "./../controllers/video_player_controller"

import Trix from "trix"
Trix.config.textAttributes.highlight = { tagName: "mark" }

addEventListener("trix-initialize", (e) => {
  var groupElement = e.target.toolbarElement.querySelector(".trix-button-group.trix-button-group--text-tools")

  groupElement.insertAdjacentHTML("beforeend", '<button type="button" class="trix-button trix-button--icon trix-button--icon-highlight" data-trix-attribute="highlight" data-trix-key="y" title="Highlight" tabindex="-1">high</button>')
  const element = document.querySelector("trix-editor");
  const actions = {
    decreaseNestingLevel: false,
    strike: false,
    increaseNestingLevel: false
  }
  element.editor.element.editorController.toolbarController.updateActions(actions);
})

const application = Application.start()
application.register("attachment", AttachmentController)
application.register("caption-checker", CaptionCheckerController)
application.register("clipboard", ClipboardController)
application.register("form", FormController)
application.register("sidebar", SidebarController)
application.register("toast", ToastController)
application.register("lazy-image", LazyImageController)
application.register("video-player", VideoPlayerController)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// Import and register all TailwindCSS Components or just the ones you need
import { Alert, Autosave, Dropdown, Tabs, Popover, Toggle } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('popover', Popover)
application.register('tabs', Tabs)

export { application }
