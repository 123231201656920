import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["player", "playbutton"]

  connect() {
    this.boundBeforeCache = this.beforeCache.bind(this)
    document.addEventListener("turbo:before-cache", this.boundBeforeCache)
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.boundBeforeCache)
  }

  play() {
    this.playerTarget.play()
    this.playbuttonTarget.classList.add('hidden')
  }

  pause() {
    this.playerTarget.pause()
    this.playbuttonTarget.classList.remove('hidden')
  }

  beforeCache() {
    if (!this.playerTarget.paused) { this.playerTarget.pause() }
  }
}
