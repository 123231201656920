import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "toggle", "secondarytoggle" ]
  static targets = [ "transitionable", "secondary" ]
  static values = { afterTimeout: Number }

  connect() {
    if (this.afterTimeoutValue) setTimeout(() => this.toggleClass(), this.afterTimeoutValue)
  }

  toggleClass() {
    this.transitionableTargets.forEach(element => {
      this.toggleClasses.forEach(className => {
        element.classList.toggle(['hidden'])
      })
    })
    this.secondaryTargets.forEach(element => {
      this.secondarytoggleClasses.forEach(className => {
        element.classList.toggle(['hidden'])
      })
    })
  }
}
