import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  handleError,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "./../helpers/index";

class Uploader {
  constructor(file, url, controller) {
    // the last 'this' argument is for directUploadWillStoreFileWithXHR
    this.directUpload = new DirectUpload(file, url, this)
    this.boundController = controller
  }

  startUpload() {
    this.directUpload.create((error, blob) => {
      if (error) {
        handleError('Something is borken. Please try again.')
      } else {
        this.boundController.hiddenTarget.value = blob.signed_id;
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => {
      this.progressUpdate(event);
    });
  }

  progressUpdate(event) {
    const progress = (event.loaded / event.total) * 100;
    this.boundController.progressTarget.style.width = progress + "%";
    if (progress >= 100) {
      this.boundController.progressTarget.parentElement.classList.add('hidden')
      this.boundController.successTarget.classList.remove('hidden')
      this.boundController.formbuttonsTarget.classList.remove('hidden')
      document.querySelector(this.boundController.formValue).toggleAttribute('data-uploading', false)
      setTimeout(() => { this.boundController.removeSuccessAnimation() }, 1000)
    }
  }
}

export default class extends Controller {
  static targets = [
    "input", "previewsource", "previewsourceaudio", "hidden", "progress", "success", "inputclicker", "videoplayer", "formbuttons", "playbutton"
  ];
  static values = {
    form: String
  }

  clickInput() {
    this.inputTarget.click()
  }

  removeSuccessAnimation() {
    this.successTarget.classList.add('hidden')
  }

  reset() {
    this.videoplayerTarget.classList.add('hidden')
    this.formbuttonsTarget.classList.add('hidden')
    this.inputclickerTarget.classList.remove('hidden')
    this.progressTarget.parentElement.classList.add('hidden')
    this.previewsourceTarget.src = null
    this.previewsourceaudioTarget.src = null
  }

  readURL(file) {
    this.inputclickerTarget.classList.add('hidden')
    let input = this.inputTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      let file = input.files[0]
      const size = (file.size / 1024 / 1024).toFixed(2)
      if (size > 2000) {
        handleError('File must be less than 2 GB')
        return
      }
      let mimeType = file.type
      let baseMimeType = mimeType.replace(/\/.*$/, "")
      if (baseMimeType !== 'video' && baseMimeType !== 'audio') {
        handleError('The computer overlords think that the file you are uploading is not a video. Try a MP4, MOV, WEBM, or MP3. Sorry, it is out of my hands.')
        return
      }
      if (baseMimeType === 'audio') {
        this.previewsourceaudioTarget.src = URL.createObjectURL(file)
        this.previewsourceaudioTarget.parentElement.toggleAttribute('controls', true)
        this.previewsourceaudioTarget.parentElement.load()
        this.previewsourceaudioTarget.parentElement.classList.remove('hidden')
        this.previewsourceTarget.parentElement.classList.add('hidden')
      } else if (baseMimeType === 'video') {
        this.previewsourceTarget.src = URL.createObjectURL(file)
        this.previewsourceTarget.parentElement.addEventListener('loadedmetadata', () => {
          const aspectRatio = this.previewsourceTarget.parentElement.videoWidth / this.previewsourceTarget.parentElement.videoHeight
          this.previewsourceTarget.parentElement.style.aspectRatio = "auto " + aspectRatio
        })
        this.previewsourceaudioTarget.parentElement.classList.add('hidden')
        this.previewsourceTarget.parentElement.classList.remove('hidden')
        this.previewsourceTarget.parentElement.toggleAttribute('controls', true)
        this.previewsourceTarget.parentElement.toggleAttribute('poster', false)
        this.previewsourceTarget.parentElement.load()
      }

      this.videoplayerTarget.classList.remove('hidden')

      reader.readAsDataURL(file);
      const uploader = new Uploader(file, '/rails/active_storage/direct_uploads', this)
      uploader.startUpload()
      this.progressTarget.parentElement.classList.remove('hidden')
      document.querySelector(this.formValue).toggleAttribute('data-uploading', true)
    }
  }
}
