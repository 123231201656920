import { post } from "@rails/request.js";

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function createQueryString (data) {
  let queryString = ''
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      queryString += `${key}=${data[key]}&`
    }
  })
  return queryString
}

export const getRequest = (uri, params, fetchCallback) => {
  const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content
  const queryString = createQueryString(params)
  window.fetch(`${uri}?${queryString}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }).then((response) => {
    response.json().then((data) => {
      if (response.status >= 200 && response.status < 400) {
        fetchCallback(null, data)
      } else if (response.status === 404) {
        history.replace(history.location.pathname, { errorStatusCode: 404 })
      } else if (response.status === 401) {
        setErrorNotification(data.errors || 'Unauthorized')
        fetchCallback({}) // want to make sure the promise is called
      } else if (response.status === 422) {
        setErrorNotification(data.errors || "I can't do that right now.")
      } else if (response.status === 403) {
        setErrorNotification(data.errors || 'Forbidden')
      } else {
        if (window.bugsnagClient) { window.bugsnagClient.notify(data.errors) } else { console.log(data.errors) }
        fetchCallback(data.errors || 'An error occured. Please try again')
      }
    })
  }).catch((err) => {
    if (window.bugsnagClient) { window.bugsnagClient.notify(err) } else { console.log(err) }
    history.replace(history.location.pathname, { errorStatusCode: 504 })
  })
}

export async function handleError(error) {
  const response = await post("/error", {
    body: { message: error },
    responseKind: "turbo-stream",
  })

  if (response.ok) { console.log(response) }
}

export async function handleToast(msg) {
  const response = await post("/toast", {
    body: { message: msg },
    responseKind: "turbo-stream",
  })

  if (response.ok) { console.log(response) }
}


